import React from "react"
import { FormattedMessage } from "react-intl"

import Layout from "../components/layout"
import FullWidthImage from "../components/full-width-image"
import SEO from "../components/seo"
import ContentWrapper from "../components/content-wrapper"

const ContactPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO page="contact" />
    <FullWidthImage page="contact" />
    <ContentWrapper>
      <h1>
        <FormattedMessage id="contact" />
      </h1>
      <h2>
        <FormattedMessage id="contact_us_headline" />
      </h2>
      <p>
        <FormattedMessage id="contact_us_text" />
      </p>
      <p>
        Dr. Monika Auer
        <br />
        EUROBRAM GmbH
        <br />
        Papiergraben 1<br />
        {locale === "pt" ? "CEP: " : ""}99425 Weimar
        <br />
        <FormattedMessage id="germany" />
      </p>
      <p>
        Tel: +49-3643-8599989
        <br />
        Fax: +49-3643-8571996
      </p>
      <p>
        <a href="mailto:office@eurobram.de">office@eurobram.de</a>
        <br />
        <a href="www.eurobram.de">www.eurobram.de</a>
      </p>
    </ContentWrapper>
  </Layout>
)

export default ContactPage
